.about-section-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  gap: 2rem;
}

.about-section-item {
  background-color: var(--color-tertiary);
  padding: 5% 8% 8%;
  text-align: center;
}

.about-section-content {
  min-height: 77%;
}

.about-section-image {
  max-height: 100px;
}

/* generate filter based on hex (converted from black): https://codepen.io/sosuke/pen/Pjoqqp */
.about-ifpa-logo {
  filter: invert(95%) sepia(95%) saturate(21%) hue-rotate(320deg) brightness(105%) contrast(105%);
}
