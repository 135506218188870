.footbag-game-section {
  padding-top: 1rem;
  padding-bottom: 2rem;
}

.footbag-game-section-with-background {
  background-color: var(--color-secondary-light);
}

.footbag-game-section-header {
	color: var(--color-secondary)
}

.footbag-game-icon {
	max-width: 200px;
}

.footbag-game-video {
  max-width: 450px;
}
