#footer-container {
	display: grid;	
	grid-template-columns: repeat(3, 1fr);
	text-align: center;
  padding-left: 5%;
  padding-right: 5%;
}

footer {
  padding: 10px;
	background: var(--color-primary);
	color: white;
}

.social {
	margin-right: 10px;
}

.copyright {
	color: var(--color-system);
	font-size: 0.8rem;
	text-align: center;
}

.ifpa-logo {
	max-height: 4rem;
}

#contact-header {
  margin-bottom: 10px;
}

#follow-us-header {
  margin-bottom: 30px;
}

.footer-link {
	color: white;
	text-decoration: none;
}

#social-container {
  margin-top: 1rem;
  margin-left: 1rem;
}

#donate-container {
  margin-top: .5rem;
}

.back-to-top {
  z-index: 99;
  border-radius: 50%;
  border: none;
  outline: none;
  position: fixed;
  right: 4%;
  bottom: 5%;
  height: 3rem;
  width: 3rem;
  cursor: pointer;
  background-color: var(--color-secondary);
  color: var(--color-primary);
  text-align: center;
  align-items: center;
  display: grid;
  grid-auto-flow: row;
  -webkit-tap-highlight-color: transparent;
}
