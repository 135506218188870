.hero-video-container {
	width: 100%;
	height: 100%;
	max-height: 550px;
	position: relative;
  pointer-events: none;
  background-color: black;
  overflow: hidden;
}

.hero-video-container iframe {
  width: 100%;
  display: block;
}

.hero-video {
	aspect-ratio: 16 / 9;
	margin-top: -10% !important;
}

.hero-gif {
  width: 100%;
  max-height: 400px;
}
