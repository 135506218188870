.how-to-play-item-container {
  margin-top: 2rem;
}

.how-to-play-item a {
  color: var(--color-primary);
}

.how-to-play-icon {
  height: 35px
}

@media (max-width: 650px) {
  .how-to-play-item-container {
    display: block;
  }

  .how-to-play-item {
    margin-bottom: 1rem;
  }

  .how-to-play-divider {
    display: none;
  }

  .how-to-play-description {
    text-align: left;
  }
}

@media (min-width: 651px) {
  .how-to-play-item-container {
    display: grid;
  }

  .how-to-play-item-container_3 {
    grid-template-columns: 1fr .01fr 1fr .01fr 1fr;
  }

  .how-to-play-item-container_2 {
    grid-template-columns: 1fr .01fr 1fr;
  }
}
