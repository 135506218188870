#events-page a {
	text-decoration: underline;
}

#events-page-header {
	text-align: center;
}

#events-title {
	font-weight: bold;
	color: var(--color-secondary);
}

.events-section-header {
  text-align: center;
  color: white;
  margin-top: 3rem;
  margin-bottom: 0;
}

.events-section-header div {
  display: inline-block;
  background-color: var(--color-primary);
  width: 200px;
  padding: .25rem;
}

#latest-event-container {
  padding-top: 3rem;
  padding-bottom: 2rem;
  background-color: var(--color-secondary-light);
}

#latest-event-logo {
  max-width: 250px;
  max-height: 250px;
}

@media (max-width: 800px) {
  #latest-event-container {
    text-align: center;
  }
}

@media (min-width: 800px) {
  #latest-event-container {
    display: grid;
    grid-template-columns: 1fr 3fr;
    column-gap: 5%;
  }
  
  #latest-event-logo-container {
    text-align: right;
  }
}

#latest-event-container .event-icon-container {
  text-align: center;
}

#latest-event-title {
  color: var(--color-secondary)
}

#past-events-container {
	display: grid;	
	grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
	text-align: center;
}

.event {
	padding: 10%;
  border: 5px solid var(--color-secondary-light);
}

.event-icon-container {
	display: grid;
	grid-template-rows: 2.5rem 1fr;
	grid-template-columns: repeat(3, 1fr);
  padding: 0 5%;
}

.event-page-link-icon {
  height: 25px;
  width: 25px;
}

#all-events-button-container {
  text-align: center;
  margin-bottom: 1rem;
}

.fa-arrow-up-right-from-square {
  color: var(--color-secondary);
  font-size: larger;
}
