.footbag-game-header {
  text-align: center;
}

@media (max-width: 700px) {
  .footbag-game-header-container_long {
    display: none;
  }

  .footbag-game-header-container_short {
    display: block;
  }

  .other-game-button {
    min-width: 100px;
  }
}

@media (min-width: 700px) {
  .footbag-game-header-container_long {
    display: grid;
    grid-template-columns: 1fr 2fr 1fr;
  }

  .footbag-game-header-container_short {
    display: none;
  }

  .other-game-button {
    min-width: 200px;
  }
}

.footbag-game-header-discover-others {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.footbag-game-other-icon {
  width: 75px;
  height: 75px;
}

.other-game-button {
  background-color: var(--color-tertiary);
}
