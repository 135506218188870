.button-link {
  color: var(--color-primary);
	background-color: var(--color-secondary);
}

.button-link_base {
  border: none;
	padding: 10px 20px;
	text-align: center;
	text-decoration: none;
	display: inline-block;
	margin: 4px 2px;
	cursor: pointer;
	border-radius: 16px;
}
