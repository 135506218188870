.header-container {
	display: grid;
  background: var(--color-primary);
	color: white;
	align-items: center;
}

.header-container_desktop {
  grid-template-columns: .15fr 2fr .75fr .25fr;
  /* active spacing is for 2 menu items, commented is for 3 */
  /* grid-template-columns: .15fr 2fr 1fr .25fr; */
	justify-content: space-between;
  align-content: space-between;
  padding: 2rem 0;
}

.header-container_mobile {
  grid-template-columns: .5fr 1fr .5fr;
  text-align: center;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 2;
}

.logo-image {
  min-width: 200px;
  max-width: 300px;
}

#email-icon {
	width: 1.5rem;
}
