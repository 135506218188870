.events-section {
  background-color: var(--color-secondary-light);
  text-align: center;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.events-section-divider {
  border-left: 1px solid black;
}

.events-section-events-container {
  margin-top: 0;
  margin-bottom: 2rem;
}

@media (max-width: 650px) {
  .events-section-past-events {
    display: none;
  }
}

@media (min-width: 650px) {
  .events-section-past-events {
    display: block;
  }

  .events-section-past-events {
    margin-top: 2rem;
  }
}

@media (max-width: 1000px) {
  .events-section-events-container {
    display: block;
  }
}

@media (min-width: 1000px) {
  .events-section-events-container {
    display: grid;
    grid-template-columns: 1fr 3fr;
    column-gap: 3rem;
  }

  .events-section-past-events {
    margin-top: 0;
  }
}

#events-section-past-events-container {
  display: grid;
  grid-template-columns: 1fr .1fr 1fr .1fr 1fr;
}

.events-section-event-container {
  background-color: white;
  padding: 1rem;
  padding-bottom: 2rem;
}

.events-section-event {
  display: inline-block;
}

.events-section-subheader {
  color: var(--color-secondary);
}
