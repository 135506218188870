.menu-item a {
  color: white;
}

.menu-item a:hover,
.menu-item:hover,
.submenu:hover {
  color: var(--color-secondary)
}

.submenu {
  display: inline;
}

.submenu-arrow {
  font-size: x-small;
  vertical-align: middle;
  width: 10px;
}

.submenu:hover {
  cursor: pointer;
}

.submenu .menu-item {
  cursor: pointer;
}

.submenu-item-container {
  background-color: var(--color-primary);
  padding: 1rem;
}

.submenu-item {
  padding: 5px 0;
  font-weight: bold;
}

.desktop-menu-container {
  display: grid;
  grid-auto-flow: column;
}

.desktop-submenu-item-container {
  position: absolute;
  z-index: 1;
}

.burger-menu-icon {
  color: var(--color-secondary);
  font-size: 5vw;
}

.mobile-menu-container {
  position: fixed;
  left: 0;
  padding-top: 2%;
  padding-bottom: 2%;
  width: 100%;
  z-index: 2;
  background-color: var(--color-primary);
}

.mobile-menu-container .menu-item {
  font-size: x-large;
  padding: 2% 0;
}
