@media (max-width: 850px) {
  .single-sport-about-content-container {
    display: block;
  }

  .single-sport-about-description {
    padding-top: 1rem;
  }

  .footbag-game-video {
    margin: 0 auto;
  }
}

@media (min-width: 850px) {
  .single-sport-about-content-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 2rem;
  }

  .footbag-game-video {
    justify-self: right;
  }
}

.single-sport-about-description {
  text-align: left;
  margin: 0;
}
