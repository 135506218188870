@import url('https://fonts.googleapis.com/css?family=Montserrat');

/* global element styles */
html {
	background: white;

	/* variables */
	--color-primary: #2c204e;
	--color-secondary: #8fe658;
	--color-secondary-light: #f4fdee;
  --color-tertiary: #F2F2F4;
	--color-system: #ccc;

  /* keep synced with 'mobileWidth' in display-hook.js */
  --width-mobile: 750px;
}

body {
	color: var(--color-primary);

	font-family: 'Montserrat';
	font-weight: 300;
	margin: 0 auto;

	font-size: 1rem;
	line-height: 1.3125rem;
}

button {
  font-family : inherit;
}

a {
	text-decoration: none;
}

img {
  max-width: 100%;
}

h1, h2, h3, h4, h5, h6 {
  line-height: 1.45;
  text-shadow: 0px .5px;
}

iframe {
  border: 0;
}

/* global class styles */
.fixed-header-space {
  height: 3rem;
  padding-top: 2%;
  padding-bottom: 2%;
}

.event-logo {
	max-height: 150px;
	max-width: 300px;
	margin: auto;
  vertical-align: middle;
}

.event-logo-container {
	height: 150px;
	line-height: 150px;
}

.page-subsection {
  padding-left: 5%;
  padding-right: 5%;
}
