@media (max-width: 500px) {
  .competitive-event-buttons-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding: 0 20%;
  }

  .competitive-event-video-button {
    display: block;
  }

  .competitive-event-video-container {
    display: none;
  }
}

@media (min-width: 500px) {
  .competitive-event-video-button {
    display: none;
  }

  .competitive-event-video-container {
    display: block;
  }
}

@media (max-width: 1000px) {
  .competitive-event-item-container {
    display: block;
  }

  .competitive-event-item {
    margin-bottom: 2rem;
  }
}

@media (min-width: 1000px) {
  .competitive-event-item-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 4%;
    padding-bottom: 4%;
  }
}

.competitive-event-item {
  background-color: white;
  padding: 5%;
}

.single-sport-competitive-event-description {
  min-height: 6rem;
}

.competitive-event-video-container {
  height: 400px;
  padding: 1rem;
}

.competitive-event-video {
  width: 100%;
	height: 100%;
}
