.intro-container {
	background-color: var(--color-secondary-light);
	text-align: center;
}

.intro-header {
	color: var(--color-secondary);
	padding-top: 1rem;
  margin-top: 0;
}

.intro-header h1 {
  margin-bottom: 0;
}

.intro-header h2 {
  margin-top: 0;
}

.footbag-description {
	font-size: medium;
	margin: 2rem 4rem 4rem 4rem;
}

.footbag-games-container {
	margin: 2rem;
	padding-bottom: 2rem;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  column-gap: 8%;
}

.footbag-games-container .footbag-game {
	margin-bottom: 1rem;
}

.footbag-game-container {
  margin-bottom: 1rem;
}

@media (min-width: 600px) {
  .footbag-game {
    height: 85%;
  }
}

.footbag-game-icon {
	border-radius: 50%;
}

.footbag-game-button {
	color: black;
	background-color: var(--color-secondary);
}

.footbag-game-button strong {
  color: black;
}
